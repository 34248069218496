import FacebookIcon from 'icons/FacebookIcon';
import Modal, { ModalCloseButton } from './Modal';
import styles from './styles/ShareModal.module.scss';
import SBLiveIcon from 'images/icons/sblive-bw-logo.png';
import Button, { ButtonSize, ButtonVariant, ButtonWidth } from './Button';
import TwitterIcon from 'icons/TwitterIcon';
import EmailIcon from 'icons/EmailIcon';
import LinkIcon from 'icons/LinkIcon';
import PrintIcon from 'icons/PrintIcon';
import { copyText } from 'helpers/copyText';
import { ReactNode, useState } from 'react';
import Clickable from './Clickable';
import { useApplicationContainer } from 'containers/ApplicationContainer';

export interface ShareModalProps {
  onClose: () => void;
  path: string;
  title?: string;
  embed?: string;
  exportLinks?: ReactNode;
}

export default function ShareModal({ onClose, path, title, embed, exportLinks }: ShareModalProps) {
  const [, { addDomain }] = useApplicationContainer();
  const url = addDomain(path);
  const [exportMenuIsOpen, setExportMenuIsOpen] = useState(false);

  function handleClickPrint() {
    if (exportLinks) {
      setExportMenuIsOpen(isOpen => !isOpen);
    } else {
      window.print();
    }
  }

  return (
    <Modal
      header={
        <div className={styles.Header}>
          <img className={styles.Logo} src={SBLiveIcon} />
          <ModalCloseButton className={styles.CloseButton} onClose={onClose} />
        </div>
      }
      onClose={onClose}
    >
      <div className={styles.Text}>Share this with your friends</div>
      <div className={styles.Icons}>
        <Button
          variant={ButtonVariant.GRAY}
          size={ButtonSize.LARGE}
          width={ButtonWidth.FULL}
          href={`https://www.facebook.com/sharer.php?u=${url}`}
          target="_blank"
        >
          <FacebookIcon />
        </Button>
        <Button
          variant={ButtonVariant.GRAY}
          size={ButtonSize.LARGE}
          width={ButtonWidth.FULL}
          href={`https://twitter.com/intent/tweet?url=${url}&text=${title || url}&via=SBLiveSports`}
          target="_blank"
        >
          <TwitterIcon />
        </Button>
        <Button
          variant={ButtonVariant.GRAY}
          size={ButtonSize.LARGE}
          width={ButtonWidth.FULL}
          href={`mailto:?subject=${title || url}&body=${url}`}
        >
          <EmailIcon />
        </Button>
        <Button variant={ButtonVariant.GRAY} size={ButtonSize.LARGE} width={ButtonWidth.FULL} onClick={() => copyText(url)}>
          <LinkIcon />
        </Button>
        <Button variant={ButtonVariant.GRAY} size={ButtonSize.LARGE} width={ButtonWidth.FULL} onClick={handleClickPrint}>
          <PrintIcon />
          {exportMenuIsOpen && (
            <div className={styles.ExportMenu}>
              <Clickable onClick={() => window.print()}>Print</Clickable>
              {exportLinks}
            </div>
          )}
        </Button>
      </div>
      {embed && (
        <div className={styles.Embed}>
          <div className={styles.EmbedLabel}>Copy Embed Code</div>
          <div className={styles.EmbedContent}>
            <div className={styles.EmbedText}>{embed}</div>
            <Button onClick={() => copyText(embed)}>COPY</Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
